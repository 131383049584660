export const ROOT = 'rs-root';

export const READY = 'ready';
export const PENDING = 'pending';
export const ERROR = 'error';
export const IDLE = 'idle';

export const RATING = 'rating-root';
export const EMPTY_PLACEHOLDER = 'empty-placeholder';
export const EMPTY_PLAIN_TEXT = 'empty-plain-text';
