import { RatingState } from '~commons/common-types';

export const buildDemoRatingState = (uuid: string, isEmpty: boolean): RatingState => {
  if (isEmpty) {
    return {
      type: 'READY_EMPTY',
    };
  }
  const randomNumber = parseInt(uuid.split('-')[0], 16) || 1337;
  return {
    type: 'READY',
    overall: Math.max(3.7, randomNumber % 5) + (randomNumber % 10) / 10,
    totalReviews: Math.max(4, randomNumber % 30),
  };
};
