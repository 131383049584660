import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams, { EmptyState } from '~ratings/settingsParams';
import { unreachable } from '~/ts-utils';
import { Placeholder } from '../placeholder/placeholder';
import { classes } from './empty-ratings.st.css';
import { useTranslate } from '../../../use-translate';
import { EMPTY_PLAIN_TEXT } from '~ratings/constants/data-hooks';

export const EmptyRatings: React.FC = () => {
  const settings = useSettings();
  const emptyStateType = settings.get(settingsParams.emptyState) as EmptyState;
  const t = useTranslate();

  switch (emptyStateType) {
    case 'blank':
      return <Placeholder />;
    case 'plainText':
      return (
        <div data-hook={EMPTY_PLAIN_TEXT} className={classes.plainText}>
          {t('ratings-widget.empty.no-reviews')}
        </div>
      );
    default:
      console.error(unreachable(emptyStateType));
      return <Placeholder />;
  }
};
