import React from 'react';
import { EMPTY_PLACEHOLDER } from '~ratings/constants/data-hooks';
import { classes } from './placeholder.st.css';

export const Placeholder: React.FC = () => {
  return (
    <div data-hook={EMPTY_PLACEHOLDER} className={classes.root}>
      {' '}
    </div>
  );
};
