import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { isOfType, unreachable } from '~/ts-utils';
import { EmptyRatings } from '../empty-ratings/empty-ratings';
import { ReadyRatings } from '../ready-ratings/ready-ratings';
import { Placeholder } from '../placeholder/placeholder';
import { classes, st } from './app.st.css';
import { ERROR, IDLE, PENDING, READY, ROOT } from '~ratings/constants/data-hooks';
import classNames from 'classnames';
import { RatingState } from '~commons/common-types';
import { useSlotContext } from '@wix/widget-plugins-ooi-context';
import { buildDemoRatingState } from '~ratings/Widget/components/app/demo-ratings';
import { useApi } from '~ratings/Widget/components/api-provider/use-api';
import settingsParams from '~ratings/settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { Alignment } from '~ratings/common-types';

const parseAlignment = (alignment: Alignment) => {
  switch (alignment) {
    case 'start':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'end':
      return 'flex-end';
    default:
      console.warn(`Alignment property ${alignment} not supported!`);
      return 'flex-start';
  }
};

export const App: React.FC = () => {
  const { isRTL, isSSR, isEditor, isPreview } = useEnvironment();
  const isDemoMode = isEditor || isPreview;

  const slotContext = useSlotContext();
  const settings = useSettings();

  const { ratingState, alignment } = useApi<{ ratingState: RatingState; alignment: Alignment }>(
    (ctx) => {
      return {
        ratingState: (() => {
          switch (ctx.type) {
            case 'single_state':
              return ctx.ratingState;
            case 'multi_state':
            default:
              if (slotContext?.type === 'initialized') {
                const { resourceId } = slotContext.value;
                const state = isDemoMode
                  ? // We don't want to wait for resourceIds to be passed in order to mock the ratingState for every
                    // resourceId. We mock the ratings in component side instead.
                    buildDemoRatingState(
                      resourceId as string,
                      ctx.state.environment.shouldShowEmptyState,
                    )
                  : ctx.state.ratingStates?.[resourceId as string];
                if (state) {
                  return state;
                }
              }
              return {
                type: 'IDLE',
              };
          }
        })(),
        alignment: ctx.type === 'single_state' ? ctx.alignment ?? 'start' : 'start',
      };
    },
  );

  // @HACK: solve hidration inconsistencies
  const Wrapper = isSSR ? 'section' : 'div';
  return (
    <Wrapper
      data-hook={classNames(ROOT, {
        [READY]: isOfType(['READY', 'READY_EMPTY'], ratingState),
        [PENDING]: isOfType(['PENDING'], ratingState),
        [ERROR]: isOfType(['ERROR'], ratingState),
        [IDLE]: isOfType(['IDLE'], ratingState),
      })}
      className={st(classes.root, {
        isEmpty:
          ratingState.type === 'READY_EMPTY' && settings.get(settingsParams.emptyState) === 'blank',
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      style={{ justifyContent: parseAlignment(alignment) }}
    >
      {(() => {
        switch (ratingState.type) {
          case 'READY':
            return (
              <ReadyRatings
                overall={ratingState.overall}
                totalReviews={ratingState.totalReviews}
                onClick={ratingState.focusReviewsComponent}
              />
            );
          case 'READY_EMPTY':
            return <EmptyRatings />;
          case 'ERROR':
          case 'IDLE':
          case 'PENDING':
            return <Placeholder />;
          default:
            throw unreachable(ratingState);
        }
      })()}
    </Wrapper>
  );
};
