import React from 'react';
import { omit } from 'lodash';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { ApiContext } from './api-context';
import {
  ControllerProps,
  MultiStateControllerProps,
  SingleStateControllerProps,
} from '~ratings/common-types';

const MultiControllerApiProvider: React.FC<WidgetProps<MultiStateControllerProps>> = (props) => {
  return (
    <ApiContext.Provider value={{ type: 'READY', context: omit(props, 'children') }}>
      {props.children}
    </ApiContext.Provider>
  );
};

const SingleControllerApiProvider: React.FC<WidgetProps<SingleStateControllerProps>> = (props) => {
  return (
    <ApiContext.Provider value={{ type: 'READY', context: omit(props, 'children') }}>
      {props.children}
    </ApiContext.Provider>
  );
};

export const ApiProvider: React.FC<WidgetProps<ControllerProps>> = (props) => {
  return props.type === 'multi_state' ? (
    <MultiControllerApiProvider {...props}>{props.children}</MultiControllerApiProvider>
  ) : (
    <SingleControllerApiProvider {...props}>{props.children}</SingleControllerApiProvider>
  );
};
